import { Input } from "antd";
import React from "react";

const DebounceInput = ({setDebouncedInputValue}) => {
  const [inputValue, setInputValue] = React.useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
        setDebouncedInputValue(inputValue);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [inputValue, 500]);

  return <Input value={inputValue} onChange={handleInputChange} placeholder="Поиск организации"/>
};

export default DebounceInput;