import { Button, DatePicker, Table, Modal, Checkbox } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import $api from "../http/api";
import ArrivalDialog from "./ArrivalDialog";

const { RangePicker } = DatePicker;

var setDetailsRef = null;

const changeChecked = async (record) => {
    if(record.docType!==0)
        return;
    const {docId, isChecked} = record;
    const resp = await $api.put(`/arrivals/${docId}/checked?isChecked=`+(!isChecked));
    if(resp.status===200)
        setDetailsRef(prev=>({...prev, items: prev.items.map(x=>{
            if(x.docId===docId & x.docType===record.docType)
                return {...x, isChecked: !isChecked};
            return {...x};
        }) }) )
}

const columns = [
    {
        title: "Дата",
        dataIndex: "dateStr",
        key: "dateStr",
    },
    {
        title: "Проверен",
        dataIndex: "isChecked",
        render: (_, record) => <>{record.docType===0 && <Checkbox checked={record.isChecked} onChange={_=>changeChecked(record) }/>}</>
    },
    {
        title: "Документ",
        dataIndex: "note",
        key: "note",
    },
    {
        title: "Сумма",
        dataIndex: "sum",
        key: "sum",
    }
]


export default function DetailPage() {
    const {state} = useLocation();
    const navigate = useNavigate();
    const {organizationId, organizationName, supplierId, supplierName, by} = state;
    const [rangeDate, setRangeDate] = useState([dayjs().add(-14, "day"), dayjs()]);
    const [details , setDetails] = useState(null);
    setDetailsRef = setDetails;

    const [selectArrival, setSelectArrival] = useState(null);

    useEffect(()=>{
        const getDetail = async () => {
            const withStr = rangeDate[0].format("DD.MM.YYYY");
            const byStr = rangeDate[1].format("DD.MM.YYYY");
            const result = await $api.get(`/report-detail?withStr=${withStr}&byStr=${byStr}&organizationId=${organizationId}&supplierId=${supplierId}`);
            if(result.status===200)
                setDetails(result.data);
        };
        getDetail();
    }, [rangeDate, selectArrival]);

    return <div>
        <ArrivalDialog selectArrival={selectArrival} setSelectArrival={setSelectArrival}/>
        <div>
            <Button onClick={_ => navigate("/")}>x</Button>
            <span>{organizationName}</span>
            <span>{supplierName}</span>
        </div>
        <div>
            <RangePicker defaultValue={rangeDate} onChange={(p1, p2) => setRangeDate(p1)} />
        </div>
        <div>
            <Button 
                onClick={_=>setSelectArrival({id: 0, num: '', date: dayjs(), sumStr: '', organizationId, supplierId, shopId: 0})}
                >Добавить приход</Button>
        </div>
        {details && <>
                <div>
                    <h3>На начало пероида: {details.startSum}</h3>
                    <div style={{"overflowX": "auto"}}>
                        <Table columns={columns} dataSource={details.items} pagination={false} size="small"/>
                    </div>
                </div>
                <h3>На конец  {details.balanceEnd}</h3>
            </>
            }
        
    </div>
}