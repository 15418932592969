import { Button, Modal, Form, Input, Select, DatePicker } from 'antd';
import { useEffect, useState } from "react";
import $api from '../http/api';

const getSUppliers = async() => {
    let result = [];
    try{
        const resp = await $api.get("/suppliers");
        if(resp.status===200)
            result = resp.data;
            return resp.data;
    } catch(e) {
        console.error(e); 
    };
    result.push({id: 0, name: ''});
    return result.map(x=>({value: x.id, label: x.name}));
}

const getShops = async(organizationId) => {
    try{
        const resp = await $api.get("/shops");
        if(resp.status===200)
            return [...resp.data.filter(x=>x.organizationId===organizationId)
                .map(x=>({value: x.id, label: x.name})), {id: 0, name: ''}];
    } catch(e) {
        console.error(e); 
    };
    return [{id: 0, name: ''}];
}

export default function ArrivalDialog({selectArrival, setSelectArrival}) {
    const organizationId = selectArrival?.organizationId ?? 0;
    const [organizations, setOrganizations] = useState([]);
    const [shops, setShops] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [selectOrganization, setSelectOrganization] = useState(null);

    useEffect(() => {
        if(selectArrival!==null)
            getShops(selectArrival.organizationId).then(x=>{
                    console.log(x);
                    setShops(x);
            });
    }, [organizationId]);

    const save = async () => {
        try{
            const { id, organizationId, supplierId, shopId, sum, num, date } = selectArrival;
            const resp = await $api.post("/arrivals", {id, organizationId, supplierId, shopId, num, sum: parseFloat(sum), date: date.format('DD.MM.YYYY')})
            if(resp.status===200)
                setSelectArrival(null);
        }
        catch(e){
            console.error(e);
        }
    }

    return <>
        {selectArrival && 
            <Modal 
                open={selectArrival!==null} 
                onCancel={_=>setSelectArrival(null)} 
                onOk={save}
                okText={selectArrival.id===0 ? 'Добавить' : 'Изменить'}>
                <Form>
                    <Form.Item label="Магазин">
                        <Select defaultValue={0} options={shops} onChange={value=>setSelectArrival(prev=>({...prev, shopId: value}))}/>
                    </Form.Item>
                    <Form.Item label="Номер">
                        <Input value={selectArrival.num} onChange={e=>setSelectArrival(prev=>({...prev, num: e.target.value}))} />
                    </Form.Item>
                    <Form.Item label="Дата">
                        <DatePicker value={selectArrival.date} onChange={date=>setSelectArrival(prev=>({...prev, date}))} />
                    </Form.Item>
                    <Form.Item label="Сумма">
                        <Input value={selectArrival.sum} onChange={e=>setSelectArrival(prev=>({...prev, sum: e.target.value}))} />
                    </Form.Item>
                </Form>
            </Modal>
        }
        </>;
    
}