import { Button, Checkbox, DatePicker, Table, Modal, Form, Input } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import $api from "../http/api";
import { useNavigate } from "react-router-dom";
import DebounceInput from "./DebounceInput";
//import locale from 'antd/es/date-picker/locale/ru_RU'
//import 'dayjs/locale/ru_RU';

var openPaymentDialogRef = null;
var navigateRef = null;

const navigateToDetail = (row) => {
    navigateRef("/detail",{state: {...row, by: dayjs().add(-1, 'day')}});
}

const columns = [
    {
        title: "Организация",
        dataIndex: "organizationName",
        key: "organizationName",
        render: (_, record) => <div onClick={_=>navigateToDetail(record)}>{record.organizationName}</div>
    },
    {
        title: "Магазин",
        dataIndex: "shopName",
        key: "shopName",
        render: (_, record) => <div onClick={_=>navigateToDetail(record)}>{record.shopName}</div>
    },
    {
        title: "Поставщик",
        dataIndex: "supplierName",
        key: "supplierName",
        render: (_, record) => <div onClick={_=>navigateToDetail(record)}>{record.supplierName}</div>
    },
    {
        title: "К оплате",
        dataIndex: "sum",
        key: "sum",
        render: (_, record) => <div onClick={_=>navigateToDetail(record)}>{record.sum}</div>
    },
    {
        title: "#",
        render:(_, record)=><>{ record.sum===0 ?
            <Button onClick={_ => openPaymentDialogRef(record)} type="link">Просмотреть</Button> : 
            <Button onClick={_ => openPaymentDialogRef(record)}>Оплатить</Button>
        }</>
        
    },
]

const getSummary = async ( groupingByShop) => {
    const result = await $api.get(`/report-summary?groupingByShop=${groupingByShop}`);
    if(result.status === 200)
        return result.data;
    return [];
}

export default function SummaryPage () {
    navigateRef = useNavigate();

    const [groupingByShop, setGroupingByShop] = useState(false);
    const [summary, setSummary] = useState([]);
    const [findText, setFindText] = useState("");

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const [lastPayment, setLastPayment] = useState(null);
    const [paySum, setPaySum] = useState("");
    
    openPaymentDialogRef = async (row) => {
        if(row.sum===0){
            const result = await $api.get(`/get-last-payment?organizationId=${row.organizationId}&supplierId=${row.supplierId}`);
            if(result.status===200 && result.data!==null)
                setLastPayment(result.data);
        }
        else{
            setPaySum(row.sum + '');
            setIsOpenModal(true);
            setSelectedRow(row);
        }
    }

    useEffect(()=>{
        getSummary( groupingByShop)
        .then(result => setSummary(result.filter(x=>x.supplierName.toLowerCase().indexOf(findText)>-1 )));
    }, [groupingByShop, findText]);

    const changeGroupingChecked = e => setGroupingByShop(e.target.checked);

    const onPay = async () => {
        const {organizationId, supplierId, sum} = selectedRow;
        const by = dayjs().format("YYYY-MM-DD");
        const sumPrepare  = parseFloat(paySum.replace(",","."));
        const result = await $api.post("/pay",{by, organizationId, supplierId, sum: sumPrepare});
        if(result.status!==200){
            alert("Ошибка сохранения");
            return;
        };

        setIsOpenModal(false);
        setSelectedRow(null);
        getSummary(groupingByShop)
            .then(result => setSummary(result));
    }

    return <div>
        <div>
            <DebounceInput setDebouncedInputValue={setFindText} />
        </div>
        <div style={{overflowY: "auto"}}>
            <Table columns={columns} dataSource={summary} pagination={false} bordered={true} size="small"/>
        </div>
        <Modal title="Оплата" open={isOpenModal} onOk={onPay} onCancel={_=>setIsOpenModal(false)}>
            <Form>
                <Form.Item label="Поставщик">
                    <Input readOnly value={selectedRow?.supplierName}/>
                </Form.Item>
                <Form.Item label="Организация">
                    <Input readOnly value={selectedRow?.organizationName}/>
                </Form.Item>
                <Form.Item label="Магазин">
                    <Input readOnly value={selectedRow?.shopName}/>
                </Form.Item>
                <Form.Item label="Сумма">
                    <Input value={paySum} onChange={e=>setPaySum(e.target.value)}/>
                </Form.Item>
            </Form>
        </Modal>

        <Modal title="Последняя оплата" open={lastPayment!==null} onOk={_=>setLastPayment(null)} onCancel={_=>setLastPayment(null)}>
            <Form>
                <Form.Item label="Поставщик">
                    <Input readOnly value={lastPayment?.supplierName}/>
                </Form.Item>
                <Form.Item label="Организация">
                    <Input readOnly value={lastPayment?.organizationName}/>
                </Form.Item>
                <Form.Item label="Дата">
                    <Input readOnly value={lastPayment?.dateStr}/>
                </Form.Item>
                <Form.Item label="Сумма">
                    <Input readOnly value={lastPayment?.sum}/>
                </Form.Item>
            </Form>
        </Modal>
    </div> ;
}